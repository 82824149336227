<template>
  <div class="download-page">
    <div class="download-wrapper">
        <div class="top">
          <img
            class="img"
            src="../../assets/zlxcimgs/second_image_xueyuan@2x.png"
          >
        </div>
        <div class="middle">
          <img
            class="img"
            src="../../assets/zlxcimgs/second_image_jiaxiao@2x.png"
          >
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadPage',
  data () {
    return {

    }
  },
  computed: {
  },
  created () { },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
